.sections {
  margin-top: 83px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 700px){
  .sections {
    margin-top: 0px;
  }
}

.name-section {
  background-color: rgb(45, 42, 45);
  margin-top: -40px;
  color: rgb(250, 250, 250);
  padding: 30px;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 15px;
}

.subtitle-name {
  text-align: center;
  font-size: 35px;
  font-weight: 100;
}

.video-section {
  padding-bottom: 800px;
  position: relative;
  display: flex;
  right: 0;
  left: 0;
  pointer-events: none;
}

.video-section iFrame {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section-title-a {
  background-color: rgb(45, 42, 45);
  color: rgb(250, 250, 250);
  padding: 35px;
  font-size: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title-b {
  background-color: rgb(237, 238, 232);
  color: rgb(45, 42, 45);
  padding: 35px;
  font-size: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects-section {
  background-color: rgb(45, 42, 45);
  padding-bottom: 50px;
}

.projects-container {

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 820px) {
  .projects-container {
    justify-content: start;
    flex-direction: column;
    align-items: center;
  }
}

.project {
  background-color: rgb(17, 17, 17);
  width: 150px;
  height: 250px;
  border: 5px solid rgb(17, 17, 17);
  border-radius: 2px;
  -webkit-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.77);
  color: white;
  font-size: 14px;
  text-align: center;
  margin: 5px;
  position: relative;
}

.project-title {
  background-color: rgb(17, 17, 17);
}

.black-cover {
  background-color: black;
  width: 150px;
  height: 200px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.project:hover {
  cursor: pointer;
}

.black-cover:hover {
  opacity: 0.5;
}

.project img {
  width: 150px;
  height: 200px;
  object-fit: cover;
  transition: 0.3s;
}

.project img:hover {
  opacity: 0.7;
}

.sd-videos-section {
  background-color: rgb(237, 238, 232);
  padding: 20px;
  padding-bottom: 50px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(300px, 400px));
  gap: 25px;
}

@media(max-width: 700px) {
  .sd-videos-section {
    grid-template-columns: 1fr;
  }
}

.sd-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 300px;
  -webkit-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.77);
}

.sd-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.soundcloud-playlists {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(250px, 400px));
  gap: 15px;
  background-color: rgb(45, 42, 45);
  padding: 50px;
  padding-bottom: 250px;

}

.playlist-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 200px;
  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);

}

.playlist-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 700px) {
  .soundcloud-playlists {
    grid-template-columns: 90%;
    grid-template-rows: repeat(2, 350px);
    row-gap: 50px;
  }

  .playlist-container {
    height: 50%;
  }

  .soundcloud-playlists {
    padding-bottom: 80px;
    padding-top: 0px;
  }
}


.biography-container {
  display: flex;
  justify-content: center;
  background-color: rgb(237, 238, 232);
  padding-bottom: 50px;
}

.biography-card {
  font-size: 14px;
  max-width: 800px;
  padding: 10px;
  display: grid;
  grid-template-columns: minmax(300px, 400px) minmax(300px, 600px);
  justify-content: center;
  grid-auto-flow: column;
  width: 80%;
  background-color: rgb(17, 17, 17);
  background: linear-gradient(-40deg, rgb(36, 36, 36) 10%, black);

  border-radius: 10px;

  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
}

.biography-picture {
  padding: 10px;

}

.biography-picture img {
  width: 100%;
}

.biography {
  display: flex;
  margin: 0;
  color: white;
  justify-content: center;
  height: 200px;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 14px;
  text-align: justify;
}

@media (max-width: 700px) {
  .biography-card {
    grid-template-columns: 80%;
    grid-template-rows: 30% 50%;
    justify-content: center;
    row-gap: 30px;
    padding: 0px;
  }
}



.contact-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 400px));
  background-color: rgb(45, 42, 45);
  justify-content: center;
  color: white;
  position: relative;
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 700px) {
  .contact-container {
    grid-template-columns: repeat(1, 100%);
    row-gap: 20px;
    padding-top: 10px;
    padding-bottom: 100px;
  }
}

.contact-left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(17, 17, 17);
  background: linear-gradient(-40deg, rgb(36, 36, 36) 0%, black);
  border-radius: 10px;
  margin: 5px;
  padding: 50px;
  gap: 5px;
  max-width: 600px;
  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
}

.lets-connect {
  font-size: 30px;
}

.lets-connect img {
  height: 40px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.seen-something {
  font-size: 15px;
}

.contact-right-container {
  text-align: right;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  justify-content: center;
  background-color: rgb(250, 250, 250);
  color: rgb(17, 17, 17);
  border-radius: 10px;
  padding-left: 10px;
  margin: 5px;
  row-gap: 10px;
  max-width: 600px;
  min-height: 190px;
  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.58);
}

.contact-right-container-line {
  display: flex;
  padding-right: 10px;
  gap: 5px;
}

.contact-right-container-line img {
  width: 25px;
}