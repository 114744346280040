body {
    font-family: 'Roboto', sans-serif;
    margin: 0px;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration-line: none;
    font-family: 'Roboto', sans-serif;
    color: white;
}

:target:before {
    content: "";
    display: block;
    height: 83px;
    margin: -83px 0 0;
}

/*
Color Scheme:
rgb(38,28,32);      dark
rgb(142,128,110);   lightbrown
rgb(147,105,81);    brown
rgb(153,161,163);   gray
rgb(250,250,250);   white
*/